// ProfileCardItem.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { differenceInHours, differenceInMinutes, fromUnixTime } from 'date-fns';

import WithMessageMetaData from '../container/WithMessageMetaData.js';
import MessageVideoPlayer from '../container/MessageVideoPlayer.js';
import MessageThumbnail from '../container/MessageThumbnail.js';
import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import Loading from '../component/Loading.jsx';

import useIsClient from '../hook/useIsClient.js';

import { ButtonId } from '../resource/mixpanel.js';
import { Status as UploadStatus } from '../resource/uploadConstants.js';
import { DrmFeatureName } from '../resource/drmConstants.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import formatNumber from '../resource/formatNumber.js';
import withViewportItemTracker from '../resource/withViewportItemTracker.js';
import { NULL_FUNCTION } from '../resource/defaults.js';

import media from '../style/media.js';
import { color } from '../style/variables.js';
import TextEllipsis from '../style/TextEllipsis.js';
import ViewersIconSrc from '../../img/ic_streaming_view_xs.svg';
import UnlockIconSrc from '../../img/ic_unlock.svg';
import DiamondIconSrc from '../../img/ic_diamond.svg';
import PinIconSrc from '../../img/ic_pin_grey_500.svg';
import ErrorIconSrc from '../../img/ic_error.svg';
import ShortIconSrc from '../../img/ic_short_white_fill.svg';
import ImageIconSrc from '../../img/photo_new.svg';
import VideoIconSrc from '../../img/ic_video.svg';

export const ProfileCardItem = ({
  messageId = '',
  clipSourceId = '',
  categoryId = '',
  isPinned = false,
  isShorts = false,
  categoryIndex = null,
  mediaType = '',
  clipStartMsec = 0,
  expiresAtUnix = 0,
  originalCaptionText = '',
  uploadJobStatus = null,
  statusI18nKey = '',
  reasonI18nKey = '',
  locationState = null,
  getElementRef = NULL_FUNCTION,
}) => {
  const { t } = useTranslation();
  const isClient = useIsClient();

  if (!messageId) {
    return (
      <StyledProfileShortCard ref={getElementRef} isShorts={isShorts}>
        <MessageCradWrapper isShorts={isShorts}>
          <Gradient />
        </MessageCradWrapper>
        <MetadataBottom></MetadataBottom>
      </StyledProfileShortCard>
    );
  }

  const getMediaTypeIconSrc = () => {
    if (isShorts) {
      return ShortIconSrc;
    }
    if (mediaType && mediaType.startsWith('image')) {
      return ImageIconSrc;
    }
    if (mediaType && mediaType.startsWith('video')) {
      return VideoIconSrc;
    }
  };

  const renderRemainTime = () => {
    if (!expiresAtUnix || isShorts) {
      return null;
    }

    const now = Date.now();
    const remainDate = fromUnixTime(expiresAtUnix);
    const remainMinutes = differenceInMinutes(remainDate, now);

    const remainTime =
      remainMinutes < 0
        ? null
        : 60 > remainMinutes
          ? t('media_timeline_minutes_left', {
              ...[remainMinutes],
              ns: TranslationNamespace.FEED,
            })
          : t('media_timeline_hours_left', {
              ...[differenceInHours(remainDate, now)],
              ns: TranslationNamespace.FEED,
            });
    return <RemainTime>{remainTime}</RemainTime>;
  };

  const isFailed = [
    UploadStatus.UPLOAD_FAILED,
    UploadStatus.PROCESSING_FAILED,
    UploadStatus.REVIEW_FAILED,
    UploadStatus.DELIVERY_FAILED,
    UploadStatus.FAILED,
  ].includes(uploadJobStatus);
  const isReviewing = [
    UploadStatus.REVIEW_STARTED,
    UploadStatus.REVIEW_COMPLETED,
    UploadStatus.DELIVERY_STARTED,
  ].includes(uploadJobStatus);
  const isLocalShorts =
    isShorts &&
    uploadJobStatus != null &&
    UploadStatus.DELIVERY_COMPLETED !== uploadJobStatus;
  const state = Object.keys(locationState || {}).length
    ? locationState
    : {
        listPath: ['home', 'messages', categoryId],
        category: categoryId,
      };
  const trackingPayload = {
    messageId,
    'discover.index': categoryIndex,
    'discover.category': categoryId,
  };
  const props = isShorts
    ? {
        to: `/shorts/${messageId}`,
        state,
        'data-element_id': ButtonId.Profile.ButtonPostClick,
        'data-tracking_payload': trackingPayload,
        onClick: event => {
          if (isLocalShorts) {
            return event.preventDefault();
          }
        },
        $disabled: isLocalShorts,
      }
    : {
        to: `/story/${messageId}${isClient ? location.search : ''}`,
        state,
        'data-element_id': ButtonId.Profile.ButtonPostClick,
        'data-tracking_payload': trackingPayload,
      };

  return (
    <StyledProfileShortCard as={Link} ref={getElementRef} {...props}>
      <MessageCradWrapper isShorts={isShorts}>
        {isLocalShorts ? (
          <MessageVideoPlayer
            messageId={clipSourceId}
            isMuted
            option={{
              autoplay: false,
              style: 'object-fit: cover',
            }}
            shouldUseSplash={false}
            drmFeatureName={DrmFeatureName.FLIX}
            onLoadedMetadata={event => {
              event.target.currentTime = clipStartMsec / 1000;
              event.target.play();
            }}
            onCanplaythrough={event => {
              event.target.pause();
            }}
          />
        ) : (
          <MessageThumbnail id={messageId} objectFit="cover" />
        )}
        <Gradient />
        {isLocalShorts && <StatusOverlay />}
        {isLocalShorts && (
          <Status>
            {isFailed ? (
              <ErrorIcon
                src={ErrorIconSrc}
                alt={t(statusI18nKey, {
                  ns: TranslationNamespace.POST,
                })}
              />
            ) : (
              <Loading />
            )}
            <StatusText
              color={
                isFailed
                  ? color.red
                  : isReviewing
                    ? color.sky
                    : color.neutral[100]
              }
            >
              {t(statusI18nKey, {
                ns: TranslationNamespace.POST,
              })}
              {reasonI18nKey
                ? ` :${t(reasonI18nKey, {
                    ns: TranslationNamespace.POST,
                  })}`
                : ''}
            </StatusText>
          </Status>
        )}
        <Caption>{originalCaptionText}</Caption>
        <TopLeftMetadata>
          {isPinned && <PinIcon src={PinIconSrc} alt="pin" />}
          {isClient ? renderRemainTime() : '-'}
        </TopLeftMetadata>
        <Icon src={getMediaTypeIconSrc()} alt="" />
      </MessageCradWrapper>
      <WithMessageMetaData messageId={messageId}>
        {({ viewCount, unlocks, price }) => {
          const isFree = price === 0;
          return isFree ? (
            <MetadataBottom>
              <RightBottom>
                <RightBottomIcon
                  src={ViewersIconSrc}
                  width={16}
                  height={16}
                  alt="view count"
                />
                {viewCount}
              </RightBottom>
            </MetadataBottom>
          ) : (
            <MetadataBottom hasPrice={true}>
              <LeftBottom>
                <LeftBottomIcon src={DiamondIconSrc} alt="diamond" />
                {price}
              </LeftBottom>
              <RightBottom>
                <RightBottomIcon src={UnlockIconSrc} alt="unlocks" />
                <RightBottomText>
                  {unlocks ? formatNumber(unlocks) : '-'}
                </RightBottomText>
              </RightBottom>
            </MetadataBottom>
          );
        }}
      </WithMessageMetaData>
    </StyledProfileShortCard>
  );
};

ProfileCardItem.propTypes = {
  messageId: PropTypes.string,
  clipSourceId: PropTypes.string,
  categoryId: PropTypes.string,
  originalTitle: PropTypes.string,
  originalCaptionText: PropTypes.string,
  uploadJobStatus: PropTypes.string,
  mediaType: PropTypes.string,
  statusI18nKey: PropTypes.string,
  reasonI18nKey: PropTypes.string,
  categoryIndex: PropTypes.number,
  clipStartMsec: PropTypes.number,
  expiresAtUnix: PropTypes.number,
  isPinned: PropTypes.bool,
  isShorts: PropTypes.bool,
  locationState: PropTypes.object,
  getElementRef: PropTypes.func,
};

const StyledProfileShortCard = styled.div.attrs(({ $disabled }) => ({
  style: {
    cursor: $disabled ? 'not-allowed' : 'pointer',
  },
}))`
  border-radius: 4px;
  overflow: hidden;
  display: block;
  width: 100%;
`;

const MessageCradWrapper = styled.div.attrs(({ isShorts }) => ({
  style: {
    aspectRatio: isShorts ? 0.56 : 0.66,
  },
}))`
  position: relative;
  > picture {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Gradient = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.05) 17%,
    rgba(0, 0, 0, 0.3) 53%,
    rgba(0, 0, 0, 0.5)
  );
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StatusOverlay = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const MetadataBottom = styled.div`
  background-color: ${color.grey[900]};
  height: 36px;
  display: flex;
  padding: 8px 6px;
  justify-content: ${({ hasPrice }) => (hasPrice ? 'space-between' : 'end')};
  align-items: center;
`;

const Status = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 16px;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
`;

const ErrorIcon = styled.img`
  width: 28px;
  height: 28px;
  object-fit: contain;
`;

const StatusText = styled.div.attrs(({ color }) => ({
  style: {
    color,
  },
}))`
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.4;
`;

const Caption = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.08px;
  word-break: break-all;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
  margin: 0px 0px 4px;
  padding: 4px 4px 0px;
  ${media.mobile`
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.048px;
  `};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  position: absolute;
  bottom: 0;
`;

const Icon = styled.img`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 32px;
  height: 32px;
  ${media.mobile`
    width: 28px;
    height: 28px;
  `};
`;

const TopLeftMetadata = styled.div`
  position: absolute;
  top: 11px;
  left: 6px;
  display: flex;
`;

const PinIcon = styled.img`
  width: 24px;
  height: 24px;
  ${media.mobile`
    width: 16px;
    height: 16px;
  `};
  margin-right: 4px;
`;

const RemainTime = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.014px;
  ${media.mobile`
    font-size: 12px;
  `};
`;

const LeftBottom = styled.div`
  margin-right: 4px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 12px;
  color: ${color.tealBlue};
  ${media.mobile`
    font-size: 12px;
  `};
`;

const RightBottomIcon = styled.img`
  width: 16px;
  height: 16px;
  opacity: 0.4;
  margin-right: 2px;
  object-fit: contain;
`;

const RightBottom = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  ${media.mobile`
    font-size: 12px;
  `};
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.048px;
  color: ${color.grey[700]};
  overflow: hidden;
`;

const RightBottomText = styled.div`
  ${TextEllipsis};
`;

const LeftBottomIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 2px;
  object-fit: contain;
  ${media.mobile`
    width: 12px;
    height: 12px;
  `};
`;

export default withViewportItemTracker(
  React.forwardRef((props, ref) => (
    <ProfileCardItem getElementRef={ref} {...props} />
  ))
);
